import { get, post, put } from "./common";
export const getUserSearchFields = async () => {
  return await get(`/user/search-fields`);
};

export const findUser = async (data) => {
  return await post(`/user/find`, data);
};

export const getProfile = async () => {
  return await get("/user/profile");
};

export const updateUser = async (data) => {
  return await put("/user/update", data);
};
