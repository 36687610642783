import {
  FILTER_OPERATOR_EQ,
  FILTER_OPERATOR_ILIKE,
  FILTER_OPERATOR_IN,
  FILTER_OPERATOR_LESS,
  FILTER_OPERATOR_MORE,
  FILTER_OPERATOR_NOT_EQ,
  FILTER_OPERATOR_NOT_ILIKE,
} from "@/helper/consts";

export const FILTER_RELATION_MODE_VALUES = 1;
export const FILTER_RELATION_MODE_STATUS = 2;

export const FILTER_RELATION_MODE_LIST = [
  {
    value: FILTER_RELATION_MODE_VALUES,
    text: "Фильровать по связи",
  },
  {
    value: FILTER_RELATION_MODE_STATUS,
    text: "Фильровать по статусу связи",
  },
];

export const EMPTY_FILTER = {
  filter: {
    name: null,
    category_id: null,
    vendor: null,
    sku_code: null,
    group_id: null,
    barcode: null,
    quantity: null,
    created_at: null,
    "prices.price": null,
    sync_status: null,
    "wildberries_relations.imt_id": null,
    "wildberries_relations.nm_id": null,
    "wildberries_relations.chrt_id": null,
    "wildberries_relations.barcode": null,
    "wildberries_relations.error": null,
    "wildberries_relations.status": null,
    "wildberries_relations.upload_status": null,
    "layers.warehouse_id": null,
    "wildberries_relations.created_at": null,
    "wildberries_relations.updated_at": null,
    sum_dimensions: null,
    max_dimension: null,
    "aliexpress_relations.ali_product_id": null,
    "aliexpress_relations.sku_code": null,
    "aliexpress_relations.sku_id": null,
    "aliexpress_relations.error": null,
    "aliexpress_relations.status": null,
    "aliexpress_relations.upload_status": null,
    "aliexpress_relations.created_at": null,
    "aliexpress_relations.updated_at": null,
    "ym_relations.shop_sku": null,
    "ym_relations.market_sku": null,
    "ym_relations.model_id": null,
    "ym_relations.status": null,
    "ym_relations.status_upload": null,
    "ym_relations.error": null,
    "ym_relations.created_at": null,
    "ym_relations.updated_at": null,
    "ym_relations.campaign_id": null,
    "ozon_relations.ozon_product_id": null,
    "ozon_relations.fbo_sku": null,
    "ozon_relations.fbs_sku": null,
    "ozon_relations.ozon_task_id": null,
    "ozon_relations.error": null,
    "ozon_relations.offer_id": null,
    "ozon_relations.status": null,
    "ozon_relations.status_upload": null,
    "ozon_relations.created_at": null,
    "ozon_relations.updated_at": null,
  },
  options: {
    wbRelations: {
      mode: FILTER_RELATION_MODE_VALUES,
    },
    aliRelations: {
      mode: FILTER_RELATION_MODE_VALUES,
    },
    ymRelations: {
      mode: FILTER_RELATION_MODE_VALUES,
    },
    ozonRelations: {
      mode: FILTER_RELATION_MODE_VALUES,
    },
  },
};

export const FILTER_NUMBER_OPERATORS = [
  {
    text: "Больше",
    value: FILTER_OPERATOR_MORE,
  },
  {
    text: "Меньше",
    value: FILTER_OPERATOR_LESS,
  },
];

export const FILTER_TEXT_OPERATORS = [
  {
    text: "Содержит",
    value: FILTER_OPERATOR_ILIKE,
  },
  {
    text: "Не содержит",
    value: FILTER_OPERATOR_NOT_ILIKE,
  },
];

export const FILTER_TEXT_AND_AREA_OPERATORS = [
  {
    text: "Содержит",
    value: FILTER_OPERATOR_ILIKE,
  },
  {
    text: "Не содержит",
    value: FILTER_OPERATOR_NOT_ILIKE,
  },
  {
    text: "Имеет одно из значений",
    value: FILTER_OPERATOR_IN,
  },
];

export const WILDBERRIES_FILTER_RELATION_STATUS = [
  {
    text: "Не выгружался",
    filter: {
      "wildberries_relations.nm_id": {
        value: "NULL",
        operator: FILTER_OPERATOR_EQ,
      },
      "wildberries_relations.error": null,
    },
  },
  {
    text: "Ошибки выгрузки",
    filter: {
      "wildberries_relations.nm_id": {
        value: "0",
        operator: FILTER_OPERATOR_EQ,
      },
      "wildberries_relations.error": {
        value: "NOT_NULL",
        operator: FILTER_OPERATOR_EQ,
      },
    },
  },
  {
    text: "Выгружен, но есть ошибки",
    filter: {
      "wildberries_relations.nm_id": {
        value: "0",
        operator: FILTER_OPERATOR_NOT_EQ,
      },
      "wildberries_relations.error": {
        value: "NOT_NULL",
        operator: FILTER_OPERATOR_EQ,
      },
    },
  },
  {
    text: "Выгружен, ошибок нет",
    filter: {
      "wildberries_relations.nm_id": {
        value: "0",
        operator: FILTER_OPERATOR_NOT_EQ,
      },
      "wildberries_relations.error": {
        value: "NULL",
        operator: FILTER_OPERATOR_EQ,
      },
    },
  },
  {
    text: "Выгружен",
    filter: {
      "wildberries_relations.nm_id": {
        value: "0",
        operator: FILTER_OPERATOR_NOT_EQ,
      },
      "wildberries_relations.error": null,
    },
  },
];

export const ALIEXPRESS_FILTER_RELATION_STATUS = [
  {
    text: "Не выгружался",
    filter: {
      "aliexpress_relations.ali_product_id": {
        value: "NULL",
        operator: FILTER_OPERATOR_EQ,
      },
    },
  },
  {
    text: "Ошибки выгрузки",
    filter: {
      "aliexpress_relations.ali_product_id": {
        value: "0",
        operator: FILTER_OPERATOR_EQ,
      },
      "aliexpress_relations.error": {
        value: "NOT_NULL",
        operator: FILTER_OPERATOR_EQ,
      },
    },
  },
  {
    text: "Выгружен, но есть ошибки",
    filter: {
      "aliexpress_relations.ali_product_id": {
        value: "0",
        operator: FILTER_OPERATOR_NOT_EQ,
      },
      "aliexpress_relations.error": {
        value: "NOT_NULL",
        operator: FILTER_OPERATOR_EQ,
      },
    },
  },
  {
    text: "Выгружен, ошибок нет",
    filter: {
      "aliexpress_relations.ali_product_id": {
        value: "0",
        operator: FILTER_OPERATOR_NOT_EQ,
      },
      "aliexpress_relations.error": {
        value: "NULL",
        operator: FILTER_OPERATOR_EQ,
      },
    },
  },
  {
    text: "Выгружен",
    filter: {
      "aliexpress_relations.ali_product_id": {
        value: "0",
        operator: FILTER_OPERATOR_NOT_EQ,
      },
    },
  },
];

export const YANDEX_FILTER_RELATION_STATUS = [
  {
    text: "Не выгружался",
    filter: {
      "ym_relations.shop_sku": {
        value: "NULL",
        operator: FILTER_OPERATOR_EQ,
      },
    },
  },
  {
    text: "Ошибки выгрузки",
    filter: {
      "ym_relations.shop_sku": {
        value: "",
        operator: FILTER_OPERATOR_EQ,
      },
      "ym_relations.error": {
        value: "NOT_NULL",
        operator: FILTER_OPERATOR_EQ,
      },
    },
  },
  {
    text: "Выгружен, но есть ошибки",
    filter: {
      "ym_relations.shop_sku": {
        value: "",
        operator: FILTER_OPERATOR_NOT_EQ,
      },
      "ym_relations.error": {
        value: "NOT_NULL",
        operator: FILTER_OPERATOR_EQ,
      },
    },
  },
  {
    text: "Выгружен, ошибок нет",
    filter: {
      "ym_relations.shop_sku": {
        value: "",
        operator: FILTER_OPERATOR_NOT_EQ,
      },
      "ym_relations.error": {
        value: "NULL",
        operator: FILTER_OPERATOR_EQ,
      },
    },
  },
  {
    text: "Выгружен",
    filter: {
      "ym_relations.shop_sku": {
        value: "",
        operator: FILTER_OPERATOR_NOT_EQ,
      },
    },
  },
];

export const OZON_FILTER_RELATION_STATUS = [
  {
    text: "Не выгружался",
    filter: {
      "ozon_relations.offer_id": {
        value: "NULL",
        operator: FILTER_OPERATOR_EQ,
      },
    },
  },
  {
    text: "Ошибки выгрузки",
    filter: {
      "ozon_relations.ozon_product_id": {
        value: 0,
        operator: FILTER_OPERATOR_EQ,
      },
      "ozon_relations.error": {
        value: "NOT_NULL",
        operator: FILTER_OPERATOR_EQ,
      },
    },
  },
  {
    text: "Выгружен, но есть ошибки",
    filter: {
      "ozon_relations.ozon_product_id": {
        value: 0,
        operator: FILTER_OPERATOR_NOT_EQ,
      },
      "ozon_relations.error": {
        value: "NOT_NULL",
        operator: FILTER_OPERATOR_EQ,
      },
    },
  },
  {
    text: "Выгружен, ошибок нет",
    filter: {
      "ozon_relations.ozon_product_id": {
        value: 0,
        operator: FILTER_OPERATOR_NOT_EQ,
      },
      "ozon_relations.error": {
        value: "NULL",
        operator: FILTER_OPERATOR_EQ,
      },
    },
  },
  {
    text: "Выгружен",
    filter: {
      "ozon_relations.ozon_product_id": {
        value: 0,
        operator: FILTER_OPERATOR_NOT_EQ,
      },
    },
  },
];

export const PRODUCT_SYNC_STATUSES = [
  {
    text: "Включена",
    value: 1,
  },
  {
    text: "Выключена",
    value: 0,
  },
];
