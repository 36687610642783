import { defineStore } from "pinia";

export const useError = defineStore("error", {
  state: () => ({
    value: null,
    timeout: null,
  }),
  getters: {
    errorMessage: (state) => state.value,
  },
  actions: {
    setError(message) {
      this.value = message;
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        this.value = null;
      }, 5000);
    },
  },
});
