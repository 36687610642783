<template>
  <v-app>
    <v-app-bar app>
      <v-app-bar-nav-icon
        class="d-lg-none"
        v-if="menuBtnVisible"
        @click="openMenu"
      ></v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <queue-list v-if="queueList" :queue-list="queueList" />
      <div v-if="user">
        <span style="font-weight: bold">{{ user.friendlyName }}</span>
        <span>({{ user.userId }})</span>
      </div>
      <v-btn elevation="2" class="mx-3" fab icon tile @click="logout()">
        <v-icon>mdi-exit-to-app</v-icon>
      </v-btn>
    </v-app-bar>
    <main-menu ref="menu" @open="hideMenuBtn" @close="showMenuBtn" />

    <v-main>
      <confirm-modal />
      <v-container fluid>
        <slot />
      </v-container>
    </v-main>
    <!--    <v-footer app>Footer</v-footer>-->
  </v-app>
</template>

<script>
import { loadAuthData, removeAuthData } from "@/storage/storage";
import { checkToken } from "@/api/common";
import MainMenu from "@/layouts/components/MainMenu.vue";
import QueueList from "@/layouts/components/QueueList.vue";
import ConfirmModal from "@/components/Common/ConfirmModal.vue";
import { mapState } from "pinia";
import { useUserProfile } from "@/pinia/profile";

export default {
  name: "DefaultLayout",
  components: { QueueList, MainMenu, ConfirmModal },
  data() {
    return {
      socket: null,
      storeId: null,
      queueList: null,
      menuBtnVisible: true,
    };
  },
  methods: {
    logout() {
      removeAuthData();
      checkToken();
    },
    socketConnect(callback) {
      const auth = loadAuthData();
      const url = "wss://app.indigolab.shop/ws";
      // const url = "ws://platform.loc/ws";
      const socket = new WebSocket(`${url}?authorization=${auth.token}`);

      socket.onopen = () => {
        this.socket = socket;
        callback();
      };
      socket.onclose = () => {
        this.socket = null;
      };
      socket.onmessage = (event) => {
        this.queueList = JSON.parse(event.data);
      };
      socket.onerror = (error) => {
        console.error(error);
      };
    },
    subscribe(storeId) {
      this.socket.send(
        JSON.stringify({
          type: "subscribe",
          storeId,
        })
      );
    },
    unSubscribe(storeId) {
      this.socket.send(
        JSON.stringify({
          type: "unsubscribe",
          storeId,
        })
      );
      this.queueList = null;
    },
    hideMenuBtn() {
      this.menuBtnVisible = false;
    },
    showMenuBtn() {
      this.menuBtnVisible = true;
    },
    openMenu() {
      this.$refs.menu.open();
    },
  },

  beforeDestroy() {
    if (this.socket) {
      this.socket.close();
    }
  },

  watch: {
    "$route.params.storeId"(storeId) {
      this.storeId = storeId;
    },

    storeId(to, from) {
      if (!this.socket) {
        this.socketConnect(() => {
          this.subscribe(to);
        });
        return;
      }
      if (to) {
        this.subscribe(to);
      } else {
        this.unSubscribe(from);
      }
    },
  },

  computed: {
    ...mapState(useUserProfile, ["user"]),
  },

  beforeMount() {
    if (this.$route.params.storeId) {
      this.storeId = this.$route.params.storeId;
    }
  },
};
</script>
