import { defineStore } from "pinia";

const DefaultOptions = {
  title: "Подтвердите действие",
  message: "Подтвердите действие",
  confirmBtnText: "Подтвердить",
  cancelBtnText: "Отмена",
};

export const useConfirm = defineStore("confirm", {
  state: () => ({
    value: {
      options: {
        color: "primary",
        width: 600,
      },
      promiseActions: {
        resolve: null,
        reject: null,
      },
      dialog: false,
      texts: {
        title: null,
        message: null,
        confirmBtnText: "Да",
        cancelBtnText: "Отмена",
      },
    },
  }),
  getters: {
    options: (state) => state.value.options,
    promiseActions: (state) => state.value.promiseActions,
    dialog: (state) => state.value.dialog,
    texts: (state) => state.value.texts,
  },
  actions: {
    confirm({ title, message, confirmBtnText, cancelBtnText, options }) {
      this.value.dialog = true;

      this.value.texts = {
        title: title ?? DefaultOptions.title,
        message: message ?? DefaultOptions.message,
        confirmBtnText: confirmBtnText ?? DefaultOptions.confirmBtnText,
        cancelBtnText: cancelBtnText ?? DefaultOptions.cancelBtnText,
      };
      this.value.options = Object.assign(this.options, options);

      return new Promise((resolve, reject) => {
        this.value.promiseActions = { resolve, reject };
      });
    },
    close() {
      this.value.dialog = false;
    },
  },
});
