import { useError } from "@/pinia/error";
import { getProfile } from "@/api/user";
import { defineStore } from "pinia";

export const useUserProfile = defineStore("userProfile", {
  state: () => ({
    value: null,
  }),
  getters: {
    user: (state) => state.value,
  },
  actions: {
    async loadProfile() {
      if (this.value) {
        return;
      }
      const { data, message } = await getProfile();
      if (message) {
        useError().setError(message);
        throw message;
      }
      this.value = data;
    },
  },
});
