<template>
  <v-dialog
    v-model="dialog"
    :max-width="options.width"
    :style="{ zIndex: options.zIndex }"
    @keydown.esc="cancel"
  >
    <v-card>
      <v-card-title
        v-if="!!texts.title"
        class="white--text"
        :class="options.color"
        >{{ texts.title }}</v-card-title
      >
      <v-card-text class="pa-6 text-start" style="font-size: 18px">{{
        texts.message
      }}</v-card-text>
      <v-card-actions class="pt-0">
        <v-spacer></v-spacer>
        <v-btn :color="options.color" class="white--text" @click="agree">{{
          texts.confirmBtnText
        }}</v-btn>
        <v-btn color="grey lighten-2" @click="cancel">{{
          texts.cancelBtnText
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { useConfirm } from "@/pinia/confirm";
import { mapState, mapActions } from "pinia";

export default {
  name: "ConfirmModal",
  data() {
    return {};
  },
  methods: {
    ...mapActions(useConfirm, ["confirm", "close"]),
    agree() {
      this.promiseActions.resolve(true);
      this.close();
    },
    cancel() {
      this.promiseActions.resolve(false);
      this.close();
    },
  },
  computed: {
    ...mapState(useConfirm, ["promiseActions", "dialog", "options", "texts"]),
  },
};
</script>
